// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArAboutNewsLinks-module--ar-gradient-blue--onj6B";
export var arGradientLight = "ArAboutNewsLinks-module--ar-gradient-light--IiNzp";
export var arH1 = "ArAboutNewsLinks-module--ar-h1--PleVi";
export var arH1Mobile = "ArAboutNewsLinks-module--ar-h1-mobile--7EGXJ";
export var arH2 = "ArAboutNewsLinks-module--ar-h2--VZDhR";
export var arH2Mobile = "ArAboutNewsLinks-module--ar-h2-mobile--j1TDc";
export var arH3 = "ArAboutNewsLinks-module--ar-h3--xdhku";
export var arH3Mobile = "ArAboutNewsLinks-module--ar-h3-mobile--ACOPB";
export var arH4 = "ArAboutNewsLinks-module--ar-h4--Hhxe+";
export var arH4Mobile = "ArAboutNewsLinks-module--ar-h4-mobile--42OyM";
export var arH5 = "ArAboutNewsLinks-module--ar-h5--5az34";
export var arH5Mobile = "ArAboutNewsLinks-module--ar-h5-mobile--1Xy7e";
export var arLinkDesktop = "ArAboutNewsLinks-module--ar-link-desktop--p-rB5";
export var arLinkMobile = "ArAboutNewsLinks-module--ar-link-mobile--2ZMUn";
export var arParagraphBase = "ArAboutNewsLinks-module--ar-paragraph-base--tzhtc";
export var arParagraphBaseMedium = "ArAboutNewsLinks-module--ar-paragraph-base-medium--yyf4k";
export var arParagraphSmall = "ArAboutNewsLinks-module--ar-paragraph-small--+RNkt";
export var arParagraphSmallMedium = "ArAboutNewsLinks-module--ar-paragraph-small-medium--yHugg";
export var arParagraphXl = "ArAboutNewsLinks-module--ar-paragraph-xl--lyhhs";
export var arParagraphXlMedium = "ArAboutNewsLinks-module--ar-paragraph-xl-medium--mMx8x";
export var arParagraphXsmall = "ArAboutNewsLinks-module--ar-paragraph-xsmall--4k4ei";
export var arParagraphXsmallMedium = "ArAboutNewsLinks-module--ar-paragraph-xsmall-medium--88A7N";
export var arQuote = "ArAboutNewsLinks-module--ar-quote--jYTk5";
export var arQuoteMobile = "ArAboutNewsLinks-module--ar-quote-mobile--uGU4n";
export var arTransition = "ArAboutNewsLinks-module--ar-transition--ExGrj";
export var container = "ArAboutNewsLinks-module--container--oQtoC";