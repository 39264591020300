// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArAboutLogos-module--ar-gradient-blue--XOmxU";
export var arGradientLight = "ArAboutLogos-module--ar-gradient-light--Kst8f";
export var arH1 = "ArAboutLogos-module--ar-h1--rNZVK";
export var arH1Mobile = "ArAboutLogos-module--ar-h1-mobile--TvwO8";
export var arH2 = "ArAboutLogos-module--ar-h2--DV9Kl";
export var arH2Mobile = "ArAboutLogos-module--ar-h2-mobile--fYvMp";
export var arH3 = "ArAboutLogos-module--ar-h3--Vg3aV";
export var arH3Mobile = "ArAboutLogos-module--ar-h3-mobile--4d3La";
export var arH4 = "ArAboutLogos-module--ar-h4--kOXNf";
export var arH4Mobile = "ArAboutLogos-module--ar-h4-mobile--UjhgO";
export var arH5 = "ArAboutLogos-module--ar-h5--u+HKF";
export var arH5Mobile = "ArAboutLogos-module--ar-h5-mobile--nHLLH";
export var arLinkDesktop = "ArAboutLogos-module--ar-link-desktop--ApGRE";
export var arLinkMobile = "ArAboutLogos-module--ar-link-mobile--Kqw73";
export var arParagraphBase = "ArAboutLogos-module--ar-paragraph-base--7UMKb";
export var arParagraphBaseMedium = "ArAboutLogos-module--ar-paragraph-base-medium--Crjbq";
export var arParagraphSmall = "ArAboutLogos-module--ar-paragraph-small--34poV";
export var arParagraphSmallMedium = "ArAboutLogos-module--ar-paragraph-small-medium--EgGIl";
export var arParagraphXl = "ArAboutLogos-module--ar-paragraph-xl--F12eJ";
export var arParagraphXlMedium = "ArAboutLogos-module--ar-paragraph-xl-medium--lhQXb";
export var arParagraphXsmall = "ArAboutLogos-module--ar-paragraph-xsmall--V5qWa";
export var arParagraphXsmallMedium = "ArAboutLogos-module--ar-paragraph-xsmall-medium--z1kd2";
export var arQuote = "ArAboutLogos-module--ar-quote--QVvOP";
export var arQuoteMobile = "ArAboutLogos-module--ar-quote-mobile--jAeVh";
export var arTransition = "ArAboutLogos-module--ar-transition--OuxO8";
export var container = "ArAboutLogos-module--container--ala-n";