// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArAboutOurTeam-module--ar-gradient-blue--i897R";
export var arGradientLight = "ArAboutOurTeam-module--ar-gradient-light--8McTv";
export var arH1 = "ArAboutOurTeam-module--ar-h1--0GubO";
export var arH1Mobile = "ArAboutOurTeam-module--ar-h1-mobile--icdHm";
export var arH2 = "ArAboutOurTeam-module--ar-h2--oCWKC";
export var arH2Mobile = "ArAboutOurTeam-module--ar-h2-mobile--ZBomb";
export var arH3 = "ArAboutOurTeam-module--ar-h3--W4ENh";
export var arH3Mobile = "ArAboutOurTeam-module--ar-h3-mobile--RA43v";
export var arH4 = "ArAboutOurTeam-module--ar-h4--b40bF";
export var arH4Mobile = "ArAboutOurTeam-module--ar-h4-mobile--Kn7LK";
export var arH5 = "ArAboutOurTeam-module--ar-h5--QBXM5";
export var arH5Mobile = "ArAboutOurTeam-module--ar-h5-mobile--FIYas";
export var arLinkDesktop = "ArAboutOurTeam-module--ar-link-desktop--fE5do";
export var arLinkMobile = "ArAboutOurTeam-module--ar-link-mobile--S4+JV";
export var arParagraphBase = "ArAboutOurTeam-module--ar-paragraph-base---QpDg";
export var arParagraphBaseMedium = "ArAboutOurTeam-module--ar-paragraph-base-medium--ATqew";
export var arParagraphSmall = "ArAboutOurTeam-module--ar-paragraph-small--s96bl";
export var arParagraphSmallMedium = "ArAboutOurTeam-module--ar-paragraph-small-medium--Gsuy3";
export var arParagraphXl = "ArAboutOurTeam-module--ar-paragraph-xl--93aQ0";
export var arParagraphXlMedium = "ArAboutOurTeam-module--ar-paragraph-xl-medium--AkT76";
export var arParagraphXsmall = "ArAboutOurTeam-module--ar-paragraph-xsmall--ZmI+d";
export var arParagraphXsmallMedium = "ArAboutOurTeam-module--ar-paragraph-xsmall-medium--APLV3";
export var arQuote = "ArAboutOurTeam-module--ar-quote--VesYo";
export var arQuoteMobile = "ArAboutOurTeam-module--ar-quote-mobile--r6LAk";
export var arTransition = "ArAboutOurTeam-module--ar-transition--zItGE";
export var container = "ArAboutOurTeam-module--container--39LLw";
export var team = "ArAboutOurTeam-module--team--054BJ";